.stats {
  overflow-x: scroll;
  flex-wrap: nowrap;
  max-width: 800px;

  @include media-breakpoint-down(sm) {
    padding-bottom: 10px !important;
  }

  scrollbar-color: #2a2a2a transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #2a2a2a;
  }
}

.stat-circle {
  height: 200px;
  width: 200px;
  @include media-breakpoint-down(sm) {
    height: 175px;
    width: 175px;
  }
}

.results-summary h3 {
  line-height: 1.5rem;
  font-weight: 100;
}

.donut-content {
  &.allow-events {
    pointer-events: none;
  }

  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
