.solana-header.header {
  margin-bottom: 0;
}

.solana-header .btn-group {
  pointer-events: none;
  cursor: default;

  &.countdown {
    min-width: 60px;
  }
}

.solana-header img.break {
  height: 40px;

  @include media-breakpoint-down(md) {
    height: 25px;
  }
}

.solana-header img.solana {
  height: 15px;

  @include media-breakpoint-down(md) {
    height: 10px;
  }
}
