.square {
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.2rem;
  padding: 0;
  border-radius: 2px;
  font-size: 10px;

  &.legend {
    border-radius: 3px;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.2rem;
  }

  @include media-breakpoint-down(sm) {
    border-radius: 3px;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.2rem;
  }
}
