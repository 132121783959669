// Icon font
@import "../fonts/feather/feather";

// Bootstrap functions
@import "~bootstrap/scss/functions.scss";

// Custom variables
@import "custom-variables";

// Dark mode variables
@import "dashkit/dark/variables-dark";

// Dashkit variables
@import "dashkit/variables";

// Bootstrap core
@import "~bootstrap/scss/bootstrap.scss";

// Dashkit core
@import "dashkit/dashkit";

// Dark mode overrides
@import "dashkit/dark/overrides-dark";

// Custom core
@import "custom";
